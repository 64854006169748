.App {
  text-align: center;
}

.btn, .form-control {
  font-family: 'Share Tech Mono';
  font-size: xx-large;
}

footer {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

input {
  color: #D93D04;
}

.btn-success:hover{
  background-color: #D9D0C7;
  border-color: #D9D0C7;
  color: #BF3636;
  font-weight: bolder;

}

.btn-primary:hover {
  background-color: #0B508C;
  border-color: #0B508C;
  color: #D9D0C7;
  font-weight: bolder;

}

.btn-success {
  background-color: #0B508C;
  border-color: #0B508C;
  color: #D9D0C7;
  font-weight: bolder;

}

.btn-primary {
  background-color: #D9D0C7;
  border-color: #D9D0C7;
  color: #BF3636;
  font-weight: bolder;
}

.btn-danger:hover {
  background-color: #D9D0C7;
  border-color: #D9D0C7;
  color: #BF3636;
  font-weight: bolder;
}

.btn-danger {
  background-color: #BF3636;
  border-color: #D9D0C7;
  color: #D9D0C7;
  font-weight: bolder;
}

.btn-secondary:hover, .btn-secondary:active{
  background-color: #BF3636;
  border-color: #D9D0C7;
  color: #D9D0C7;
  font-weight: bolder;
}

.btn-secondary {
  background-color: #BF3636;
  border-color: #D9D0C7;
  color: #D9D0C7;
  font-weight: bolder;
}

input[type="number"]:disabled {
  background-color: #D9D0C7;
  border-color: #D9D0C7;
  color: #BF3636;
  font-weight: bolder;
}

input[type="text"]:disabled {
  background-color: #D9D0C7;
  border-color: #D9D0C7;
  color: #0B508C;
  font-weight: bolder;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

#input {
  border: 1px solid black;
  border-radius: 6px;
}

#display {
  margin-left: .25em;
  margin-right: .25em;
}


.card {
  margin-left: auto;
  margin-right: auto;
  width: 25em;
  text-align: center;
  margin-top: 4em;
  background-color: #BF3636;
  box-shadow: rgba(191, 53, 53, .5) 0px 10px 20px, rgba(191, 53, 53, .5) 0px 10px 12px;
}

.numbers {
  max-width: 4.25em;
  margin-left: .1em;
  margin-right: .1em;
}

.row {
  margin-top: .5em;
  margin-bottom: .5em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/* Color Theme Swatches in Hex */
.Red-White-and-Blue-Boards-Background-1-hex { color: #A63C45; }
.Red-White-and-Blue-Boards-Background-2-hex { color: #0B508C; }
.Red-White-and-Blue-Boards-Background-3-hex { color: #23648C; }
.Red-White-and-Blue-Boards-Background-4-hex { color: #D9D0C7; }
.Red-White-and-Blue-Boards-Background-5-hex { color: #BF3636; }

/* Color Theme Swatches in RGBA */
.Red-White-and-Blue-Boards-Background-1-rgba { color: rgba(165, 59, 68, 1); }
.Red-White-and-Blue-Boards-Background-2-rgba { color: rgba(11, 80, 140, 1); }
.Red-White-and-Blue-Boards-Background-3-rgba { color: rgba(35, 99, 140, 1); }
.Red-White-and-Blue-Boards-Background-4-rgba { color: rgba(216, 207, 199, 1); }
.Red-White-and-Blue-Boards-Background-5-rgba { color: rgba(191, 53, 53, 1); }

/* Color Theme Swatches in HSLA */
.Red-White-and-Blue-Boards-Background-1-hsla { color: hsla(354, 47, 44, 1); }
.Red-White-and-Blue-Boards-Background-2-hsla { color: hsla(208, 85, 29, 1); }
.Red-White-and-Blue-Boards-Background-3-hsla { color: hsla(202, 60, 34, 1); }
.Red-White-and-Blue-Boards-Background-4-hsla { color: hsla(28, 18, 81, 1); }
.Red-White-and-Blue-Boards-Background-5-hsla { color: hsla(0, 56, 48, 1); }
