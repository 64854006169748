html {
  /* background-color: linear-gradient(to bottom, #F27405, #D95204); */
  background: #D9D0C7;
  background: linear-gradient(90deg, #23648C 0%, #D9D0C7 35%, #23648C 100%);
}

body {
  margin: 0;
  font-family: 'Graduate', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
